import { useState, useEffect } from "react";
import usePartySocket from "partysocket/react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import Chat from "./Chat";

const questions = [
  {
    question:
      "What's the height difference between our Badge in code and in Figma?",
    answer: ["2px"],
  },
  {
    question:
      "Which Telescope Lab component is the most used according to the Telescope Metrics?",
    answer: ["SkeletonLoader"],
  },
  {
    question: `The default "Amount" for the Telescope Tooltip in Figma is...`,
    answer: ["Three"],
  },
  {
    question:
      "Which of our three pictogram animals is not a mammal and not a reptitle?",
    answer: ["Snail"],
  },
  {
    question: `What's the number of missing components in "Web components: Telescope" in Figma?`,
    answer: ["9"],
  },
  {
    question: `Name one of the two icons in our icon set that has the longest name`,
    answer: ["CategoryMealsAndDrinks", "EmotionSlightlySmiling"],
  },
  {
    question:
      "Who is the original author of the CallToAction in Telescope Lab?",
    answer: ["Peter Scott"],
  },
  {
    question: `The current color of colorContentInteractiveLink in dark mode is...`,
    answer: ["pink600"],
  },
  {
    question:
      "The heading seen when navigating to a page on the Telescope website that doesn't exist is...",
    answer: ["Something is fishy!"],
  },
];

const rewardLetters = "LASEREYES";

function shuffleString(str: string) {
  return str
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");
}

type Player = {
  name: string;
  score: number;
};

type GameState = {
  players: Player[];
  gameStarted: boolean;
};

type Message = {
  sender: string;
  content: string;
  timestamp: number;
};

const randomColor = () =>
  `hsl(${Math.random() * 360}, ${50 + Math.random() * 50}%, ${
    50 + Math.random() * 50
  }%)`;
const randomFontSize = () => `${12 + Math.random() * 8}px`;
const randomBorder = () =>
  `${1 + Math.random() * 5}px ${
    ["solid", "dotted", "dashed", "double"][Math.floor(Math.random() * 4)]
  } ${randomColor()}`;

const getRandomColor = () => {
  const hue = Math.floor(Math.random() * 360);
  return `hsl(${hue}, 80%, 50%)`;
};

const getRandomTextColor = () => {
  const hue = Math.floor(Math.random() * 360);
  return `hsl(${hue}, 80%, 90%)`;
};

export default function Game() {
  const [username, setUsername] = useState("");
  const [gameState, setGameState] = useState<GameState>({
    players: [],
    gameStarted: false,
  });
  const [input, setInput] = useState("");
  const [obtainedLetters, setObtainedLetters] = useState("");
  const [finalAnswer, setFinalAnswer] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasJoined, setHasJoined] = useState(false);
  const [currentPlayerQuestion, setCurrentPlayerQuestion] = useState(0);
  const [gameWon, setGameWon] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [bgColor, setBgColor] = useState("hsl(220, 30%, 10%)");
  const controls = useAnimation();

  const socket = usePartySocket({
    room: "escape-room",
    onMessage(event) {
      const data = JSON.parse(event.data);
      if (data.type === "gameState") {
        setGameState(data.state);
      } else if (data.type === "chatMessage") {
        setMessages((prevMessages) => [data.message, ...prevMessages]);
      }
    },
  });

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    const storedHasJoined = localStorage.getItem("hasJoined");
    const storedQuestion = localStorage.getItem("currentQuestion");
    const storedLetters = localStorage.getItem("obtainedLetters");

    if (storedUsername && storedHasJoined === "true") {
      setUsername(storedUsername);
      setHasJoined(true);
      if (storedQuestion) {
        setCurrentPlayerQuestion(parseInt(storedQuestion, 10));
      }
      if (storedLetters) {
        setObtainedLetters(storedLetters);
      }
      socket.send(
        JSON.stringify({
          type: "rejoin",
          name: storedUsername,
          question: storedQuestion ? parseInt(storedQuestion, 10) : 0,
        })
      );
    }

    const interval = setInterval(() => {
      // Randomly animate UI elements
      controls.start({
        scale: [1, 1.05, 1],
        rotate: [0, 5, -5, 0],
        transition: { duration: 0.5 },
      });
    }, 5000); // Every 5 seconds

    return () => clearInterval(interval);
  }, [controls]);

  useEffect(() => {
    // Apply background to body
    document.body.style.background = `linear-gradient(135deg, ${bgColor}, hsl(220, 30%, 5%))`;
    document.body.style.margin = "0";
    document.body.style.minHeight = "100vh";
  }, [bgColor]);

  const joinGame = () => {
    if (username && !hasJoined) {
      socket.send(JSON.stringify({ type: "join", name: username }));
      setHasJoined(true);
      localStorage.setItem("username", username);
      localStorage.setItem("hasJoined", "true");
    }
  };

  const leaveGame = () => {
    socket.send(JSON.stringify({ type: "leave", name: username }));
    setHasJoined(false);
    setUsername("");
    setCurrentPlayerQuestion(0);
    setObtainedLetters("");
    setGameWon(false);
    localStorage.removeItem("username");
    localStorage.removeItem("hasJoined");
    localStorage.removeItem("currentQuestion");
    localStorage.removeItem("obtainedLetters");
    localStorage.removeItem("gameWon");
  };

  const startGame = () => {
    socket.send(JSON.stringify({ type: "start" }));
  };

  const checkAnswer = () => {
    if (
      currentPlayerQuestion < questions.length &&
      questions[currentPlayerQuestion].answer.find(
        (answer) => answer.toLowerCase() === input.toLowerCase()
      )
    ) {
      const newQuestion = currentPlayerQuestion + 1;
      const newLetters = shuffleString(
        obtainedLetters + rewardLetters[currentPlayerQuestion]
      );

      socket.send(JSON.stringify({ type: "correctAnswer", name: username }));
      setObtainedLetters(newLetters);
      setCurrentPlayerQuestion(newQuestion);
      setInput("");

      localStorage.setItem("currentQuestion", newQuestion.toString());
      localStorage.setItem("obtainedLetters", newLetters);
    } else {
      setErrorMessage(getRandomErrorMessage());
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
    }
  };

  const getRandomErrorMessage = () => {
    const messages = [
      "WRONG! The ghost of wrong answers haunts you!",
      "Incorrect! A skeleton laughs at your mistake!",
      "Nope! The walls seem to close in a little...",
      "Error! A spooky wind howls through the room!",
      "Mistaken! The candles flicker ominously...",
    ];
    return messages[Math.floor(Math.random() * messages.length)];
  };

  const checkFinalAnswer = () => {
    if (finalAnswer.toLowerCase() === "laser eyes") {
      socket.send(JSON.stringify({ type: "gameWon", name: username }));
      setGameWon(true);
      localStorage.setItem("gameWon", "true");
    } else {
      setShowError(true);
      setTimeout(() => setShowError(false), 2000);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (currentPlayerQuestion < questions.length) {
        checkAnswer();
      } else {
        checkFinalAnswer();
      }
    }
  };

  const getRandomStyle = () => ({
    color: getRandomTextColor(),
    backgroundColor: getRandomColor(),
    fontSize: randomFontSize(),
    border: randomBorder(),
    padding: `${Math.random() * 10}px`,
    borderRadius: `${Math.random() * 20}px`,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hue = 220 + Math.random() * 20;
    const lightness = 10 + Math.random() * 5;
    setBgColor(`hsl(${hue}, 30%, ${lightness}%)`);
  };

  const currentPlayer = gameState.players.find((p) => p.name === username);

  const renderGameContent = () => {
    if (gameWon || localStorage.getItem("gameWon") === "true") {
      return (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1, rotate: 360 }}
          transition={{ duration: 1, ease: "easeOut" }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "60vh",
          }}
        >
          <h2 style={{ fontSize: "3rem", color: "#4CAF50" }}>
            Congratulations!
          </h2>
          <p style={{ fontSize: "1.5rem", textAlign: "center" }}>
            You've escaped the room and won the game!
          </p>
          <motion.div
            animate={{
              scale: [1, 1.2, 1],
              rotate: [0, 360, 0],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.5, 1],
              repeat: Infinity,
            }}
            style={{
              width: 100,
              height: 100,
              borderRadius: "50%",
              background: "linear-gradient(45deg, #ff00ff, #00ffff)",
              marginTop: 20,
            }}
          />
        </motion.div>
      );
    }

    if (currentPlayerQuestion < questions.length) {
      return (
        <motion.div>
          <motion.h2
            key={currentPlayerQuestion}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              color: getRandomTextColor(),
              textShadow: "2px 2px 4px #000000",
            }}
          >
            Question {currentPlayerQuestion + 1}
          </motion.h2>
          <motion.p
            key={questions[currentPlayerQuestion].question}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            style={{ color: getRandomTextColor(), fontSize: "18px" }}
          >
            {questions[currentPlayerQuestion].question}
          </motion.p>
          <motion.input
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
              handleInputChange(e);
            }}
            onKeyPress={handleKeyPress}
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "10px",
              borderRadius: "5px",
              border: "2px solid #ff0000",
              background: "#1a1a1a",
              color: "#ffffff",
              fontSize: "16px",
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          />
          <motion.button
            onClick={checkAnswer}
            style={{
              backgroundColor: "#ff0000",
              border: "none",
              color: "white",
              padding: "15px 32px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              margin: "4px 2px",
              cursor: "pointer",
              borderRadius: "5px",
              transition: "all 0.3s ease",
              boxShadow: "0 0 10px #ff0000",
            }}
            whileHover={{ scale: 1.05, boxShadow: "0 0 20px #ff0000" }}
            whileTap={{ scale: 0.95 }}
          >
            Submit Answer
          </motion.button>
        </motion.div>
      );
    }

    return (
      <>
        <motion.h3
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Final Challenge
        </motion.h3>
        <motion.input
          value={finalAnswer}
          onChange={(e) => setFinalAnswer(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Enter the final two words"
          style={{
            width: "100%",
            padding: "10px",
            marginBottom: "10px",
            borderRadius: "5px",
            border: "2px solid #333",
          }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        />
        <motion.button
          onClick={checkFinalAnswer}
          style={{
            backgroundColor: "#f44336",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "24px",
            margin: "4px 2px",
            cursor: "pointer",
            borderRadius: "5px",
            transition: "all 0.3s ease",
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Escape!
        </motion.button>
      </>
    );
  };

  const sendMessage = (content: string) => {
    const message: Message = {
      sender: username,
      content,
      timestamp: Date.now(),
    };
    socket.send(JSON.stringify({ type: "chatMessage", message }));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      style={{
        fontFamily: "'Creepster', cursive",
        width: "100vw",
        height: "100vh",
        margin: 0,
        padding: "20px",
        background:
          "url('https://example.com/spooky-background.jpg') no-repeat center center fixed",
        backgroundSize: "cover",
        boxSizing: "border-box",
        color: "#ffffff",
        display: "grid",
        gridTemplateColumns: "1fr 300px",
        gridTemplateRows: "auto 1fr auto",
        gridTemplateAreas: `
          "header header"
          "main leaderboard"
          "main chat"
        `,
        gap: "20px",
      }}
    >
      <motion.h1
        style={{
          gridArea: "header",
          textAlign: "center",
          color: "#ff0000",
          textShadow: "2px 2px 4px #000000",
          fontSize: "48px",
          margin: 0,
        }}
      >
        Escape Room Challenge
      </motion.h1>

      <motion.div
        style={{
          gridArea: "main",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 0 10px #ff0000",
          overflowY: "auto",
        }}
      >
        <AnimatePresence>
          {showError && (
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
              style={{
                background: "rgba(255, 0, 0, 0.8)",
                color: "#ffffff",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 0 20px #ff0000",
                textAlign: "center",
                fontSize: "24px",
                marginBottom: "20px",
              }}
            >
              {errorMessage}
            </motion.div>
          )}
        </AnimatePresence>
        {!hasJoined ? (
          <>
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter name"
              style={{
                width: "100%",
                padding: "10px",
                marginBottom: "10px",
                borderRadius: "5px",
                border: "2px solid #333",
                background: "transparent",
              }}
            />
            <button onClick={joinGame}>Join Game</button>
          </>
        ) : !gameState.gameStarted ? (
          <>
            <button onClick={startGame}>Everybody is in</button>
            <button onClick={leaveGame} style={{ marginLeft: "10px" }}>
              Leave Game
            </button>
          </>
        ) : (
          <>
            {renderGameContent()}
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              Obtained letters: {obtainedLetters}
            </motion.p>
            <button onClick={leaveGame} style={{ marginTop: "20px" }}>
              Leave Game
            </button>
          </>
        )}
      </motion.div>

      <motion.div
        style={{
          gridArea: "leaderboard",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 0 10px #ff0000",
          overflowY: "auto",
        }}
      >
        <h2 style={{ color: "#ff0000", textShadow: "2px 2px 4px #000000" }}>
          Leaderboard
        </h2>
        {gameState.players
          .sort((a, b) => b.score - a.score)
          .map((player, index) => (
            <div
              key={player.name}
              style={{
                padding: "10px",
                margin: "5px 0",
                backgroundColor:
                  index === 0
                    ? "rgba(255, 215, 0, 0.3)"
                    : "rgba(255, 255, 255, 0.1)",
                borderRadius: "5px",
                color: "#ffffff",
              }}
            >
              {player.name}: {player.score}
            </div>
          ))}
      </motion.div>

      {hasJoined && gameState.gameStarted && (
        <div style={{ gridArea: "chat", maxHeight: "50vh", overflowY: "auto" }}>
          <Chat
            username={username}
            sendMessage={sendMessage}
            messages={messages}
          />
        </div>
      )}
    </motion.div>
  );
}
