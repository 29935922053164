import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

type Message = {
  sender: string;
  content: string;
  timestamp: number;
};

type ChatProps = {
  username: string;
  sendMessage: (content: string) => void;
  messages: Message[];
};

const Chat: React.FC<ChatProps> = ({ username, sendMessage, messages }) => {
  const [input, setInput] = useState("");
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = 0;
    }
  }, [messages]);

  const handleSend = () => {
    if (input.trim()) {
      sendMessage(input.trim());
      setInput("");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        fontFamily: "'Creepster', cursive",
      }}
    >
      <div
        ref={chatContainerRef}
        style={{
          flex: 1,
          overflowY: "auto",
          padding: "10px",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        {messages.map((msg, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
            style={{
              marginBottom: "10px",
              padding: "5px",
              backgroundColor:
                msg.sender === username
                  ? "rgba(255, 0, 0, 0.3)"
                  : "rgba(0, 255, 0, 0.3)",
              borderRadius: "5px",
              maxWidth: "80%",
              alignSelf: msg.sender === username ? "flex-end" : "flex-start",
            }}
          >
            <div style={{ fontSize: "12px", color: "#aaa" }}>
              {msg.sender} - {new Date(msg.timestamp).toLocaleTimeString()}
            </div>
            <div style={{ color: "#fff" }}>{msg.content}</div>
          </motion.div>
        ))}
      </div>
      <div style={{ display: "flex", padding: "10px" }}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
          style={{
            flex: 1,
            width: 0,
            marginRight: "5px",
            padding: "5px",
            borderRadius: "5px",
            border: "2px solid #ff0000",
            backgroundColor: "#1a1a1a",
            color: "#fff",
          }}
          placeholder="Type a message..."
        />
        <motion.button
          onClick={handleSend}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          style={{
            padding: "5px 10px",
            borderRadius: "5px",
            border: "none",
            backgroundColor: "#ff0000",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          Send
        </motion.button>
      </div>
    </motion.div>
  );
};

export default Chat;
